<template>
  <div class="bonus">
    <div class="bonus_m1">
      <div class="m flex">
        <p style="color: #fff">
          主流交易算法框架下的量化CTA基金，<br />为客户提供优质的投资产品选择
        </p>
      </div>
    </div>
    <div class="bonus_m2" v-if="is_active == 3">
      <div class="m">
        <i class="icon iconfont icon-loukongguanli"></i>
        <a href="/">首页</a>
        <i class="icon iconfont icon-jiantou_liebiaoxiangyou_o"></i>
        <a href="javascript:void(0)">基金产品</a>
        <!-- <input type="file" @change="readExcel" multiple> -->
      </div>
    </div>
    <div class="bonus_m3" v-if="is_active == 3">
      <div class="m">
        <el-row :gutter="12">
          <el-col
            :xs="12"
            :sm="8"
            style="margin-bottom: 10px"
            v-for="item in fund_listArr"
            :key="item.fund_id"
          >
            <el-card shadow="hover" style="cursor: pointer">
              <span
                style="display: block; width: 100%; height: 100%"
                @click="chartsOpen(item)"
              >
                {{ item.fund_name }}
              </span>
            </el-card>
          </el-col>

          <!-- <el-col :span="8">
            <el-card
              shadow="hover"
              style="cursor: pointer"
            >
              <span
                style="display: block; width: 100%; height: 100%"
                @click="chartsOpen('A1')"
              >
                大凡广金0017
              </span>
            </el-card>
          </el-col> -->
        </el-row>
      </div>
      <div style="text-align: center">
        <el-pagination
          background
          layout="prev, pager, next,total"
          :total="total"
          :page-size="pagesize"
          @current-change="pageChange"
        ></el-pagination>
      </div>
    </div>

    <!-- <div v-else>
      <el-empty description="暂无权限查看！"></el-empty>
    </div> -->
  </div>
</template>
    <script>
import Papa from "papaparse";
import * as XLSX from "xlsx";
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters(["UserId", "token", "is_active"]),
  },
  components: {},
  name: "bonus",
  data() {
    return {
      total: 0, //总条数
      pagesize: 10, //指定展示多少条
      currentPage: 1, //当前页码
      fund_listArr: [],
      csvData: null,
      currentTab: "产品分红",
      tabs: ["产品分红", "净值周报"],
      lisActive: "大凡私募证劵投资基金0021",
      tabs_list: [
        {
          id: "0",
          text: "大凡私募证劵投资基金0021",
        },
        {
          id: "1",
          text: "大凡私募证劵投资基金0019",
        },
        {
          id: "2",
          text: "  大凡2.0B私募证劵投资基金0028",
        },
        {
          id: "3",
          text: "大凡私募证券投资基金0032",
        },
        {
          id: "4",
          text: "大凡私募证券投资基金0052",
        },
        {
          id: "5",
          text: "大凡广金私募证劵投资基金0017",
        },
        {
          id: "6",
          text: "大凡私募证劵投资基金0037",
        },
      ],
    };
  },
  watch: {
    is_active: {
      handler: function (newval, oldval) {
        if (this.is_active == 3) {
          if (this.token != "") {
            this.ajax(this.currentPage, this.pagesize);
          } else {
            this.$message.warning("token失效,请重新登录");
          }
        }
      },
    },
  },
  mounted() {
    this.$nextTick(() => {
      //this.handleAnimate(); //初始化第一次加载时在视口内就执行动画
      addEventListener("scroll", this.handleScroll);
      addEventListener("scroll", this.handleAnimate);
    });

    if (this.is_active == 3) {
      if (this.token != "") {
        this.ajax(this.currentPage, this.pagesize);
      } else {
        this.$message.warning("token失效,请重新登录");
      }
    }
  },
  destroyed() {
    removeEventListener("scroll", this.handleScroll); //避免影响其他页面
    removeEventListener("scroll", this.handleAnimate);
  },
  methods: {
    pageChange(e) {
      this.ajax(e, this.pageSize);
    },
    // total: 0,//总条数
    //     pagesize:10,//指定展示多少条
    //     currentPage:1,//当前页码
    ajax(page, size) {
      this.$store
        .dispatch("user/fund_list", {
          page: page,
          size: size,
        })
        .then((e) => {
          this.fund_listArr = e.data;
          this.total = e.count;
        })
        .catch(() => {});
    },
    chartsOpen(obj) {
      // const routeData = this.$router.resolve("./charts");
      const routeData = this.$router.resolve({
        path: "/charts",
        query: obj,
      });
      window.open(routeData.href, "_blank");
    },
    readExcel(event) {
      const files = event.target.files;
      for (let i = 0; i < files.length; i++) {
        const reader = new FileReader();
        reader.onload = (e) => {
          const data = new Uint8Array(e.target.result);
          const workbook = XLSX.read(data, { type: "array" });
          const firstSheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[firstSheetName];
          const json = XLSX.utils.sheet_to_json(worksheet);
        };
        reader.readAsArrayBuffer(files[i]);
      }
    },
    handleScroll() {
      // 执行滚动到当前元素时想要执行的操作
      // console.log('Scrolled to .my-element');
    },

    handleAnimate() {
      let top =
        pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      let vh = document.documentElement.clientHeight;
      let dom = document.querySelectorAll(".animated");
      [].slice.call(dom).forEach((v) => {
        if (top + vh > v.offsetTop) {
          var delay = v.dataset.delay;
          if (delay) {
            setTimeout(() => {
              v.style.opacity = 1;
              v.classList.add(v.dataset.ani);
            }, delay);
          } else {
            v.style.opacity = 1;
            v.classList.add(v.dataset.ani);
          }
        } else {
          v.classList.remove(v.dataset.ani);
          v.style.opacity = 0;
        }
      });
    },
  },
};
</script>
  <style lang="scss" scoped>
@media (max-width: 975px) {
  .bonus {
  }
}
.bonus {
  background: #fafafa;
  width: 100%;
  margin-top: -30px;
  overflow: hidden;
  .bonus_m3 {
    overflow: hidden;
    margin: 40px 0px;
    min-height: 400px;
    .m {
      width: 1200px;

      height: auto;
      margin: 0px auto;
      .l {
        width: 205px;
        border-right: 1px solid #e4e4e4;
        ul {
          width: 100%;
          overflow: hidden;
          margin-top: 20px;
          li {
            width: 100%;
            height: 56px;
            line-height: 56px;
            a {
              display: block;
              width: auto;
              height: 100%;
              padding-left: 25px;
              padding-right: 10px;
              font-size: 18px;
              color: #999;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
            .li1 {
              a {
                display: block;
                width: auto;
                height: 100%;
                padding-left: 45px;
                padding-right: 10px;
                font-size: 16px;
                color: #999;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }
            }
          }
          .on {
            a {
              color: #000;
              font-weight: bold;
            }
          }
        }
      }
      .r {
        flex: 1;
        padding: 15px 0px;
        overflow: hidden;
        .r-c {
          width: 845px;
          height: auto;
          overflow: hidden;
          padding: 20px 40px;
          box-shadow: 0px 0px 10px #ddd;
          margin: 20px auto;
          .t-m {
            table {
              width: 100%;
              margin-top: 20px;
              th {
                line-height: 30px;
                font-size: 18px;
                color: #333;
                font-weight: normal;
                padding-bottom: 20px;
                text-align: center;
              }
              td {
                ine-height: 50px;
                font-size: 16px;
                color: #999;
                font-weight: normal;
                text-align: center;
                .red {
                  color: #e60012;
                }
              }
            }
          }
          .t {
            border-bottom: 1px dashed #e5e5e5;
            padding-bottom: 20px;
            display: flex;
            .t-z {
              flex: 1;
              margin-right: 20px;
              .p1 {
                overflow: hidden;
                font-size: 18px;
                color: #000;
              }
              .p2 {
                overflow: hidden;
                font-size: 16px;
                color: #999;
                margin-top: 3px;
              }
            }
            .t-y {
              width: auto;
              display: flex;
              a {
                display: inline-block;
                background: #fff;
                border: 1px solid #7d7d7d;
                font-size: 16px;
                color: #000;
                width: auto;
                height: 40px;
                text-align: center;
                line-height: 40px;
                border-radius: 5px;
                padding: 0px 20px;
                align-self: center;
              }
            }
          }
        }
        ul {
          width: auto;
          overflow: hidden;
          padding: 0px 60px;
          li {
            width: 100%;
            height: 80px;
            border-bottom: 1px dashed #e5e5e5;
            line-height: 80px;
            display: flex;
            overflow: hidden;
            a {
              display: block;
              flex: 1;
              overflow: hidden;
              height: 100%;
              line-height: 80px;
              font-size: 18px;
              color: #000;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
            span {
              line-height: 80px;
              margin-left: 20px;
              font-size: 16px;
              color: #999;
            }
          }
        }
      }
    }
  }
}
.bonus_m2 {
  overflow: hidden;
  line-height: 30px;
  font-size: 15px;
  color: #666;
  padding: 25px 0px 0px 0px;
  .m {
    width: 1200px;
    height: auto;
    margin: 0px auto;
    .iconfont {
      font-family: "iconfont" !important;
      font-size: 16px;
      font-style: normal;
      -webkit-font-smoothing: antialiased;
    }
    a {
      color: #666;
    }
  }
}
.bonus_m1 {
  background-image: url(../assets/0c149c3d5eaa7f4dc0a5d9479a79bf3.png);
  width: 100%;
  height: 589px;
  background-repeat: no-repeat;
  background-position: center center;
  .m {
    width: 1200px;
    height: auto;
    margin: 0px auto;
    display: flex;
    p {
      align-self: center;
      font-size: 24px;
      color: #000;
      line-height: 44px;
      padding-top: 180px;
    }
  }
}
</style> 